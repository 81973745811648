@mixin respond($breakpoint) {
   @if $breakpoint==mediumDesktop {
      @media only screen and (min-width: 1201px) and (max-width: 1400px) {
         @content;
      }
   }
   @if $breakpoint==smallDesktop {
      @media only screen and (min-width: 1025px) and (max-width: 1200px) {
         @content;
      }
   }
   @if $breakpoint==smallDesktopAndTablet {
      @media only screen and (min-width: 601px) and (max-width: 1200px) {
         @content;
      }
   }
   @if $breakpoint==tablet {
      @media only screen and (min-width: 601px) and (max-width: 1024px) {
         @content;
      }
   }
   @if $breakpoint==mobile {
      @media only screen and (max-width: 600px) {
         @content;
      }
   }
   @if $breakpoint==tabletAndMobile {
      @media only screen and (max-width: 1024px) {
         @content;
      }
   }
}
