.admin-orders {
   & .user-orders__header {
      & > :nth-child(3) {
         @include respond(mobile) {
            display: none;
         }
      }

      & > :nth-child(5) {
         @include respond(mobile) {
            display: none;
         }
      }
   }

   &__toggle {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      gap: 30px;
      align-items: center;
      margin-bottom: 40px;
      text-align: center;

      @include respond(tabletAndMobile) {
         grid-template-columns: 1fr;
      }
      & .btn-group {
         @include respond(tabletAndMobile) {
            margin: 0 10px;
         }
      }
      & form,
      & > div:last-child {
         display: flex;
         align-items: center;
         justify-content: flex-end;
         gap: 8px;
         font-size: 24px;
         text-align: right;
         @include respond(tabletAndMobile) {
            justify-content: center;
         }
         & input {
            width: 195px;
         }
         & .svg-inline--fa {
            cursor: pointer;
         }
         & .btn {
            padding: 4px 12px;
         }
      }
   }

   & .white-block {
      margin-bottom: 20px;
   }

   &__header {
      border: 1px solid #dee2e6;
      border-radius: 5px;
      padding: 10px 20px;
      display: flex;
      width: 100%;
      align-items: center;
      box-sizing: border-box;
      margin-bottom: 10px;
      background-color: $primaryBackgroundColor;
      font-weight: 600;
      & :first-child {
         width: 105px;
      }
      & :nth-child(2) {
         width: 205px;
      }
      & :nth-child(3) {
         width: 105px;
      }
      & :nth-child(4) {
         width: 180px;
      }
      & :nth-child(5) {
         width: 150px;
      }
   }
}

.admin-product-list {
   & a {
      text-decoration: none;
   }
   &__hour-group {
      background-color: rgba($color: $accentColor, $alpha: 0.07);
      font-weight: 600;
      text-align: center;
   }

   & tr {
      & > td:first-child {
         width: 125px;
      }
      & > td:nth-child(2) {
         width: 250px;
      }
      & > td:nth-child(3),
      & > th:nth-child(3) {
         width: 100px;
         text-align: center;
      }

      & > td:nth-child(5),
      & > th:nth-child(5) {
         width: 80px;
         text-align: right;
         & .svg-inline--fa {
            cursor: pointer;
            color: orange;
         }
      }
   }
   &__picto {
      width: 32px;
      height: 32px;
      vertical-align: middle;
      @include respond(tabletAndMobile) {
         width: 1.5rem;
         height: 1.5rem;
      }
   }
}
