.tag {
   display: inline-block;
   padding: 5px 15px;
   border-radius: 7px;
   border: 2px solid $accentColor;
   font-weight: 500;
   text-align: center;

   &--primary,
   &--warning {
      color: $accentColor;
      border-color: $accentColor;
   }
   &--secondary {
      color: $secondaryTextColor;
      border-color: $secondaryTextColor;
   }

   &--danger {
      color: $redColor;
      border-color: $redColor;
   }
}
