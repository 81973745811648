.order-accordion {
   margin-bottom: 10px;
   &__picto {
      width: 28px;
      height: 28px;
      margin-right: 12px;
      @include respond(tabletAndMobile) {
         width: 1.5rem;
         height: 1.5rem;
         margin-right: 6px;
      }
   }

   &__line,
   &__line-admin {
      cursor: pointer;
      display: table-row;
      & > div {
         display: table-cell;
         vertical-align: middle;
         padding: 16px 6px;
         border-top: 1px solid #dee2e6;
         border-bottom: 1px solid #dee2e6;
      }
      & > div:first-child {
         font-weight: 600;
         padding-left: 1rem;
         border-radius: 5px 0 0 5px;
         border-left: 1px solid #dee2e6;
      }
      & > div:last-child {
         text-align: right;
         padding-right: 1rem;
         border-radius: 0 5px 5px 0;
         border-right: 1px solid #dee2e6;
      }
      & .tag {
         font-size: 0.95rem;
         padding: 0.25rem 0.4rem;
         box-sizing: border-box;
         overflow: hidden;
         text-overflow: ellipsis;
         max-width: 100%;
         @media only screen and (max-width: 375px) {
            font-size: 0.85rem;
         }
      }
   }

   &__line {
      & > div:nth-child(4) {
         max-width: 22vw;
      }
   }

   &__line-admin {
      & .two-columns {
         gap: 10px;

         @include respond(tabletAndMobile) {
            flex-direction: column;
         }

         & .warning-text {
            font-size: 0.8rem;
         }
      }
      & > div:nth-child(3) {
         @include respond(mobile) {
            display: none;
         }
      }

      & > div:nth-child(5) {
         @include respond(mobile) {
            display: none;
         }
      }
      & > div:nth-child(6) {
         max-width: 22vw;
      }
   }

   &__line--warning > div,
   &__line--warning > div:first-child,
   &__line--warning > div:last-child {
      border-color: $redColor;
   }

   &__line--warning {
      background-color: $redBackgroundColor;
   }

   &__product,
   &__product-header {
      display: grid;
      grid-template-columns: 1fr 3fr 1fr 1fr 26px;
      align-items: center;
      gap: 8px;
      & :nth-child(2) {
         display: flex;
         gap: 10px;
         align-items: center;
      }
      & :nth-child(2) .order-accordion__picto {
         width: 20px;
         height: 20px;
         margin-right: 0px;
      }
      & :nth-child(4),
      & :last-child {
         text-align: right;
      }
   }

   &__product-header {
      font-weight: 600;
   }

   &__product {
      margin-top: 10px;
   }

   &__comment {
      margin: 5px 0 10px 0;
      font-size: 14px;
      border: 1px solid orange;
      background-color: rgb(255, 245, 224);
      border-radius: 5px;
      padding: 4px 8px;
      grid-column: 1 / 5;
      display: flex;
      align-items: center;
      gap: 10px;
      & .svg-inline--fa {
         transform: rotate(90deg);
      }
   }
   &__footer {
      margin-top: 20px;
      align-items: stretch;
      gap: 30px;
      & > div:first-child {
         flex-grow: 1;
         border: 0 solid $separatorColor;
         background-color: $primaryBackgroundColor;
         border-radius: 5px;
         padding: 6px 10px;
         box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15);
      }
      & > div {
      }
   }

   &__customer,
   &__actions {
      //line-height: 130%;
      font-size: 15px;
      font-style: italic;
      display: flex;
      flex-direction: column;
   }

   &__customer {
      gap: 0;
   }

   &__actions {
      gap: 12px;
   }

   &__actions > div:last-child {
      align-self: flex-end;
      display: flex;
      gap: 10px;
   }

   &__small-btn {
      padding: 2px 6px;
      //line-height: 75%;
   }

   &__red {
      color: $redColor;
   }
   & .icon {
      cursor: pointer;
   }
}

.accordion-button {
   cursor: pointer;
}
