.account {
   &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 75px;
      @include respond(tabletAndMobile) {
         margin-bottom: 35px;
      }
      & h1 {
         margin-bottom: 25px;
      }
   }

   &__content {
      @include respond(tabletAndMobile) {
         flex-direction: column;
         align-items: stretch;
         gap: 20px;
      }
   }
   &__orders {
      flex-grow: 1;
   }

   &__infos {
      width: 35%;
      min-width: 380px;
      flex-shrink: 0;
      @include respond(tabletAndMobile) {
         width: unset;
         flex-shrink: 1;
         min-width: unset;
      }
   }

   &__identification {
      display: grid;
      grid-template-columns: 1fr min-content 1fr;
      grid-template-areas:
         "connect separator create"
         "actions actions actions";
      row-gap: 15px;
      margin: 0 8%;
      @include respond(tabletAndMobile) {
         grid-template-areas:
            "connect"
            "actions"
            "separator"
            "create";
         grid-template-columns: 1fr;
         margin: 0;
         max-width: 580px;
         margin: 0 auto;
      }
   }

   &__connect {
      grid-area: connect;
      display: flex;
      flex-direction: column;
   }

   &__separator {
      grid-area: separator;
      margin: 0 5vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: 600;
      @include respond(tabletAndMobile) {
         margin: 20px 0;
         flex-direction: row;
         gap: 10px;
      }

      & :nth-child(2) {
         padding: 10px 0;
         color: $accentColor;
      }
      & :first-child,
      & :last-child {
         width: 1px;
         border-left: 1px solid $separatorColor;
         height: 48%;
         @include respond(tabletAndMobile) {
            border-left: unset;
            border-top: 1px solid $separatorColor;
            width: 48%;
            height: unset;
         }
      }
   }

   &__create {
      grid-area: create;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      & p {
         margin-bottom: 20px;
      }
      & .btn {
         margin-top: auto;
         @include respond(tabletAndMobile) {
            margin-top: 20px;
         }
      }
   }
   &__actions {
      grid-area: actions;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      margin-top: 20px;
      font-size: 0.9rem;
      @include respond(tabletAndMobile) {
         padding: 0 25px;
         text-align: center;
      }
   }
}
