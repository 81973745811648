.product-card {
   display: flex;
   flex-direction: column;
   position: relative;

   &--highlighted {
      border: 2px solid $accentColor;
      @include respond(mobile) {
         border-right: unset;
         border-left: unset;
      }
   }

   &__highlighted-box {
      cursor: pointer;
      position: absolute;
      z-index: 10;
      top: 12px;
      left: 12px;
      border-radius: 10px;
      background-color: $accentColor;
      padding: 4px 10px;
      color: white;
      font-weight: 500;
      font-size: 16px;
      @include respond(tabletAndMobile) {
         top: 10px;
         left: 10px;
         font-size: 15px;
      }
   }

   &__disabled {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      inset: 0; // correspond au top, right, left, bottom
      z-index: 20;
      color: white;
      background-color: rgba($color: #565656, $alpha: 0.7);
      font-size: 28px;
      padding: 10px;
      pointer-events: none;
   }

   &__edit {
      &-checkbox,
      &-button {
         position: absolute;
         z-index: 30;
         top: 0;
      }
      &-checkbox {
         border-radius: 0 0 25px 0;
         left: 0;
         &--selected {
            background-color: $accentColor;
         }
      }
      &-button {
         border-radius: 0 0 0 25px;
         right: 0;
      }
   }

   &__organic-logo {
      position: absolute;
      top: 133px;
      right: 15px;
      height: 42px;
      background-color: white;
      padding: 5px;
   }

   &__image {
      object-fit: cover;
      cursor: pointer;
      height: 200px;
      width: 100%;
   }

   &__container {
      padding: 15px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: stretch;
      flex-grow: 1;
   }

   &__title,
   &__subtitle {
      text-align: center;
   }

   &__title {
      font-size: 20px;
      margin-bottom: 13px;
      //line-height: 120%;
      font-weight: 500;
   }

   &__title a {
      text-decoration: none;
      color: $primaryTextColor;
   }

   &__details {
      margin-top: auto;
      text-align: center;
   }

   &__approximative {
      font-size: 13px;
      color: $secondaryTextColor;
      font-weight: 500;
      margin-bottom: 6px;
   }

   & .btn-group {
      margin-bottom: 8px;
   }

   & .btn-group .btn {
      padding: 2px 11px;
      font-size: 14px;
   }

   &__separator {
      font-size: 14px;
      color: #bbb;
      margin: 0 10px;
   }

   &__bottom {
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: space-between;
   }

   &__price {
      font-size: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
   }

   &__price-per-kg {
      font-size: 11px;
      color: $secondaryTextColor;
   }

   & .quantity-selector span {
      min-width: 22px;
      font-size: 16px;
   }

   & .quantity-selector button {
      padding: 8px;
   }

   &__add {
      padding: unset;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
   }
   &__not-bookable {
      text-align: right;
      padding-left: 22px;
      font-size: 14px;
      color: $secondaryTextColor;
   }
   & .btn--animated {
      animation: wobble 0.75s ease-in-out;
   }

   & .btn--animated:hover {
      background-color: unset;
      color: $accentColor;
   }
}

@keyframes wobble {
   25% {
      transform: scale(0.8);
   }
   50% {
      transform: scale(1.2);
   }
   75% {
      transform: scale(0.9);
   }
}
