.policy {
   & .page-container {
      position: relative;
      @include respond(mobile) {
         padding: 0 $mobilePaddingX;
      }
   }
   & .edit-button {
      position: absolute;
      top: 75px;
      right: 15px;
   }
}

.policy-edit {
   & .markdown-editor .editor-input {
      max-height: 500px;
   }
   &__buttons {
      margin-top: 25px;
      display: flex;
      justify-content: center;
      gap: 25px;
   }
}
