.hours-selector {
   &__header {
      & .svg-inline--fa {
         transform: rotate(180deg);
         margin-top: 7px;
      }
      display: flex;
      align-items: flex-start;
      gap: 10px;
      margin-bottom: 15px;
      padding-left: 4px;
   }
   &__grid {
      display: grid;
      grid-template-columns: min-content 4fr 3fr 3fr min-content;
      gap: 8px;
      align-items: center;
      & .btn {
         padding-left: 12px;
         padding-right: 12px;
      }
      @include respond(mobile) {
         gap: 4px;
      }
   }
   &__temporary {
      background-color: rgb(252, 244, 169);
   }

   &__buttons {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      gap: 10px;
   }
}
