// general
$accentColor: #308029;
$secondaryColor: #dfdfdf;
$separatorColor: #cccccc;
$redColor: #cc2315;

// text color
$primaryTextColor: #111111;
$secondaryTextColor: #6e6e6e;
$tertiaryTextColor: #a1a1a1;

// background color
$primaryBackgroundColor: #f6f4f1;
$secondaryBackgroundColor: #111111;
$redBackgroundColor: #fff5f5;

// padding
$defaultPagePadding: 40px 50px 75px 50px;
$mobilePagePadding: 20px 0 50px 0;
$tabletPagePadding: 40px 40px 75px 40px;
$mobilePaddingX: 15px;