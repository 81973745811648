.password-strength {
   margin: 10px 0;
   &__title {
      font-size: 12px;
      color: $secondaryTextColor;
   }

   &__container {
      margin: 8px 0;
      border-radius: 5px;
      background-color: #f3f3f3;
      height: 10px;
   }

   &__bar {
      height: 10px;
      border-radius: 5px;
   }
   &__bar--low1,
   &__bar--low2 {
      background-color: $redColor;
   }
   &__bar--low1 {
      width: 16%;
   }
   &__bar--low2 {
      width: 33%;
   }
   &__bar--medium1,
   &__bar--medium2 {
      background-color: orange;
   }
   &__bar--medium1 {
      width: 49%;
   }
   &__bar--medium2 {
      width: 66%;
   }
   &__bar--high1,
   &__bar--high2 {
      background-color: $accentColor;
   }
   &__bar--high1 {
      width: 83%;
   }
   &__bar--high2 {
      width: 100%;
   }
}
