.product-info {
   & .page-container {
      min-height: 600px;
      @include respond(tabletAndMobile) {
         padding: 0 $mobilePaddingX;
      }
   }
   & h2 {
      font-size: 19px;
      margin-bottom: 15px;
      padding-top: 25px;
   }
   & h2 .svg-inline--fa {
      font-size: 17px;
      margin-right: 8px;
      color: $accentColor;
   }
   & h2 ~ p {
      font-size: 15px;
      margin-bottom: 7px;
   }
   &__content {
      display: flex;
      gap: 50px;
      @include respond(tabletAndMobile) {
         flex-direction: column;
         gap: 25px;
      }
   }

   &__highlighted {
      position: absolute;
      top: 20px;
      left: 20px;
      border-radius: 10px;
      background-color: $accentColor;
      color: white;
      padding: 5px 12px;
      font-weight: 500;
      font-size: 18px;
      @include respond(tabletAndMobile) {
         top: 15px;
         left: 15px;
         font-size: 16px;
      }
   }

   &__image {
      width: 50%;
      position: relative;
      @include respond(tabletAndMobile) {
         width: 100%;
      }
   }

   &__photo {
      height: 50vw;
      max-height: 500px;
      width: 100%;
      border-radius: 25px;
      object-fit: cover;
      @include respond(tabletAndMobile) {
         max-height: 400px;
         height: 58vw;
      }
   }

   &__organic-logo {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 100px;
      background-color: white;
      border-radius: 4px;
      @include respond(tablet) {
         top: 20px;
         right: 20px;
         width: 90px;
      }
      @include respond(mobile) {
         top: 15px;
         right: 15px;
         width: 70px;
      }
   }

   &__details {
      width: 50%;
      @include respond(tabletAndMobile) {
         width: 100%;
      }

      & .tag {
         margin-bottom: 15px;
         @include respond(tabletAndMobile) {
            padding: 5px 10px;
            font-size: 14px;
         }
      }

      & > h2:first-of-type {
         border-top: 1px solid $separatorColor;
      }
   }

   &__title {
      margin-bottom: 15px;
   }

   &__description {
      margin-bottom: 30px;
   }

   &__price-quantity {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      gap: 20px;
      @include respond(tabletAndMobile) {
         gap: 10px;
      }
   }

   &__not-bookable {
      text-align: right;
      margin-left: auto;
      color: $secondaryTextColor;
      font-size: 0.9rem;
   }

   &__variant {
      min-width: 55px;
      padding: 9px 12px;
      border: 1px solid $accentColor;
      border-radius: 5px;
      color: $accentColor;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include respond(tabletAndMobile) {
         padding: 6px 8px;
      }
   }
   &__approximative {
      font-size: 11px;
   }

   &__price {
      display: flex;
      flex-direction: column;
      gap: 3px;

      & :first-child {
         font-weight: 400;
         font-size: 14px;
         color: $secondaryTextColor;
      }

      & :last-child {
         font-size: 24px;
         font-weight: 600;
         @include respond(tabletAndMobile) {
            font-size: 21px;
         }
      }
   }

   &__quantity {
      display: flex;
      gap: 15px;
      margin-left: auto;
      @include respond(tabletAndMobile) {
         gap: 5px;
      }
   }

   &__add {
      & span {
         @media only screen and (max-width: 375px) {
            display: none;
         }
      }

      & .svg-inline--fa {
         display: none;
         @media only screen and (max-width: 375px) {
            display: unset;
         }
      }
   }
}
