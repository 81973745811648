.bread-crumb {
   display: flex;
   align-items: center;
   gap: 15px;
   padding: 0 5px;
   margin: 0 auto 55px auto;
   max-width: 1600px;
   overflow-x: scroll;
   min-height: 1.5rem;
   -ms-overflow-style: none; /* Hide scrollbar for IE, Edge */
   scrollbar-width: none; /* Hide scrollbar for Firefox */
   @include respond(tablet) {
      margin-bottom: 40px;
      gap: 8px;
      font-size: 1rem;
   }
   @include respond(mobile) {
      padding: 0 $mobilePaddingX;
      margin-bottom: 30px;
      gap: 8px;
      font-size: 0.9rem;
   }
   & > * {
      flex-shrink: 0;
   }
   & a {
      text-decoration: none;
   }
   &::-webkit-scrollbar {
      display: none; /* Hide scrollbar for Chrome, Safari and Opera */
   }
}
