.error-block {
   text-align: center;

   &__type {
      color: $accentColor;
      font-weight: 700;
      font-size: 11rem;
      margin-bottom: 1rem;
   }

   &__description {
      font-size: 1.6rem;
      margin-bottom: 5rem;
   }
}
