.news-article {
   &__banner {
      height: 50vw;
      max-height: 400px;
      margin-bottom: 50px;
      @include respond(mobile) {
         margin-bottom: 30px;
      }

      & img {
         height: 100%;
         border-radius: 25px;
      }
   }

   &__header {
      position: relative;
      margin-bottom: 45px;
      & .edit-button {
         position: absolute;
         top: 0;
         right: 0;
      }
   }
   & h1 {
      margin-bottom: 1rem;
   }

   & .page-container {
      @include respond(mobile) {
         padding: 0 $mobilePaddingX;
      }
   }

   &__event-date {
      text-align: center;
      font-size: 1.45rem;
      color: $accentColor;
   }

   &__title {
      margin-bottom: 25px;
   }

   &__date {
      color: $secondaryTextColor;
   }

   &__text {
      margin-bottom: 20px;
   }

   &__share {
      text-align: center;
      padding: 10px 0 20px 0;
      & #dropdown-share {
         border-radius: 25px;
      }
   }
   &__footer {
      border-top: 1px solid $separatorColor;
      padding: 20px 0 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      font-weight: 500;
      @include respond(mobile) {
         flex-direction: column;
         gap: 20px;
      }
   }
}
