.markdown-editor {
   & .ltr {
      text-align: left;
   }

   & .rtl {
      text-align: right;
   }

   & .editor-container {
      color: #000;
      position: relative;
      text-align: left;
      border: 1px solid var(--bs-border-color);
      border-radius: 7px;
   }

   & .editor-inner {
      background: #fff;
      position: relative;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
   }

   & .editor-input {
      min-height: 100px;
      max-height: 240px;
      resize: none;
      position: relative;
      tab-size: 1;
      outline: 0;
      padding: 15px 10px;
      caret-color: #444;
      overflow: scroll;
   }

   & .editor-placeholder {
      color: #999;
      overflow: hidden;
      position: absolute;
      text-overflow: ellipsis;
      top: 15px;
      left: 10px;
      font-size: 15px;
      user-select: none;
      display: inline-block;
      pointer-events: none;
   }

   & .editor-text-bold {
      font-weight: bold;
   }

   & .editor-text-italic {
      font-style: italic;
   }

   & .editor-text-underline {
      text-decoration: underline;
   }

   & .editor-text-strikethrough {
      text-decoration: line-through;
   }

   & .editor-text-underlineStrikethrough {
      text-decoration: underline line-through;
   }

   & .editor-text-code {
      background-color: rgb(240, 242, 245);
      padding: 1px 0.25rem;
      font-family: Menlo, Consolas, Monaco, monospace;
   }

   & .editor-link {
      color: $accentColor;
      text-decoration: none;
   }

   & .editor-paragraph {
      margin: 0;
      margin-bottom: 8px;
      position: relative;
   }

   & .editor-paragraph:last-child {
      margin-bottom: 0;
   }

   & .editor-heading-h1 {
      //font-size: 24px;
   }

   & .editor-heading-h2 {
      //font-size: 15px;
   }

   & .editor-quote {
      margin: 0;
      margin-left: 20px;
      font-size: 18px;
      color: rgb(101, 103, 107);
      border-left-color: rgb(206, 208, 212);
      border-left-width: 4px;
      border-left-style: solid;
      padding-left: 16px;
   }

   & .editor-list-ol {
      padding: 0;
      margin: 0;
      margin-left: 16px;
      list-style: decimal;
   }

   & .editor-list-ul {
      padding: 0;
      margin: 0;
      margin-left: 16px;
      list-style: disc;
   }

   & .editor-listitem {
      margin: 8px 32px 8px 32px;
   }

   & .editor-nested-listitem {
      list-style-type: none;
   }

   & pre::-webkit-scrollbar {
      background: transparent;
      width: 10px;
   }

   & pre::-webkit-scrollbar-thumb {
      background: #999;
   }

   & .toolbar {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      background: #f5f5f5;
      padding: 8px;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
   }

   & .toolbar button.toolbar-item {
      border: 0;
      display: flex;
      align-items: center;
      gap: 8px;
      background: none;
      border-radius: 7px;
      padding: 8px;
      cursor: pointer;
   }

   & .toolbar button.toolbar-item:disabled {
      cursor: not-allowed;
   }

   & .toolbar button.toolbar-item i.format {
      background-size: contain;
      height: 22px;
      width: 22px;
      opacity: 0.6;
   }

   & .toolbar button.toolbar-item:disabled i.format {
      opacity: 0.25;
   }

   & .toolbar button.toolbar-item.active {
      background-color: rgba(223, 232, 250, 0.3);
   }

   & .toolbar button.toolbar-item.active i {
      opacity: 1;
   }

   & .toolbar .toolbar-item:hover:not([disabled]) {
      background-color: #e5e5e5;
   }

   & .toolbar .divider {
      width: 2px;
      height: 20px;
      margin: 8px 12px;
      background-color: #e5e5e5;
   }

   & .toolbar select.toolbar-item {
      border: 0;
      display: flex;
      background: none;
      border-radius: 7px;
      padding: 8px;
      vertical-align: middle;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 70px;
      color: #777;
      text-overflow: ellipsis;
   }

   & .toolbar select.code-language {
      text-transform: capitalize;
      width: 130px;
   }

   & .toolbar .toolbar-item .text {
      font-size: 15px;
      color: #666;
      width: 130px;
      overflow: hidden;
      text-align: left;
   }

   & .toolbar .toolbar-item .icon {
      width: 22px;
      height: 22px;
      user-select: none;
      background-size: contain;
   }

   & .toolbar i.chevron-down {
      width: 18px;
      height: 18px;
      user-select: none;
      opacity: 0.8;
   }

   & .toolbar i.chevron-down.inside {
      width: 16px;
      height: 16px;
      display: flex;
      margin-left: -25px;
      margin-top: 11px;
      margin-right: 10px;
      pointer-events: none;
   }

   & i.chevron-down {
      background-color: transparent;
      background-size: contain;
      display: inline-block;
      height: 8px;
      width: 8px;
      background-image: url(../../../public/assets/editor/chevron-down.svg);
   }

   &-dropdown {
      z-index: 2000;
      display: flex;
      flex-direction: column;
      gap: 2px;
      position: absolute;
      box-shadow:
         0 12px 28px 0 rgba(0, 0, 0, 0.2),
         0 2px 4px 0 rgba(0, 0, 0, 0.1),
         inset 0 0 0 1px rgba(255, 255, 255, 0.5);
      border-radius: 7px;
      min-width: 100px;
      min-height: 40px;
      background-color: #fff;
      padding: 6px;
   }

   &-dropdown-item {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      flex-grow: 1;
      gap: 12px;
      padding: 6px;
      cursor: pointer;
      font-size: 15px;
      background-color: #fff;
      border-radius: 7px;
      border: 0;
      color: $primaryTextColor;
   }

   &-dropdown-item i.format {
      background-size: contain;
      height: 22px;
      width: 22px;
      opacity: 0.6;
   }

   &-dropdown-item:disabled {
      cursor: not-allowed;
      opacity: 0.4;
   }

   &-dropdown-item .active {
      width: 16px;
      height: 16px;
      background-image: url(../../../public/assets/editor/checkmark.svg);
   }

   &-dropdown-item:hover {
      background-color: #e5e5e5;
   }

   &-dropdown-item .text {
      flex-grow: 1;
      text-align: left;
      width: 140px;
   }

   &-dropdown-item .icon {
      display: flex;
      width: 20px;
      height: 20px;
      user-select: none;
      margin-right: 12px;
      //line-height: 16px;
      background-size: contain;
   }

   & .link-editor {
      position: absolute;
      z-index: 2000;
      top: -10000px;
      left: -10000px;
      margin-top: -6px;
      max-width: 300px;
      width: 100%;
      opacity: 0;
      background-color: #fff;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      transition: opacity 0.5s;
   }

   & .link-editor .link-input {
      display: block;
      width: calc(100% - 24px);
      box-sizing: border-box;
      margin: 8px 12px;
      padding: 8px 12px;
      border-radius: 15px;
      background-color: #eee;
      font-size: 15px;
      color: rgb(5, 5, 5);
      border: 0;
      outline: 0;
      position: relative;
      font-family: inherit;
   }

   & .link-editor div.link-edit {
      background-image: url(../../../public/assets/editor/pencil-fill.svg);
      background-size: 16px;
      background-position: center;
      background-repeat: no-repeat;
      width: 35px;
      vertical-align: -0.25em;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      cursor: pointer;
   }

   & .link-editor .link-input a {
      color: rgb(33, 111, 219);
      text-decoration: none;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      margin-right: 30px;
      text-overflow: ellipsis;
   }

   & .link-editor .link-input a:hover {
      text-decoration: underline;
   }

   & .link-editor .button {
      width: 20px;
      height: 20px;
      display: inline-block;
      padding: 6px;
      border-radius: 8px;
      cursor: pointer;
      margin: 0 2px;
   }

   & .link-editor .button.hovered {
      width: 20px;
      height: 20px;
      display: inline-block;
      background-color: #eee;
   }

   & .link-editor .button i,
   & .actions i {
      background-size: contain;
      display: inline-block;
      height: 20px;
      width: 20px;
      vertical-align: -0.25em;
   }

   & i.undo {
      background-image: url(../../../public/assets/editor/arrow-counterclockwise.svg);
   }

   & i.redo {
      background-image: url(../../../public/assets/editor/arrow-clockwise.svg);
   }

   &-dropdown-item i.paragraph,
   & i.paragraph {
      background-image: url(../../../public/assets/editor/text-paragraph.svg);
   }

   &-dropdown-item i.h1,
   & i.h1 {
      background-image: url(../../../public/assets/editor/type-h1.svg);
   }

   &-dropdown-item i.h2,
   & i.h2 {
      background-image: url(../../../public/assets/editor/type-h2.svg);
   }

   &-dropdown-item i.h3,
   & i.h3 {
      background-image: url(../../../public/assets/editor/type-h3.svg);
   }

   &-dropdown-item i.ul,
   & i.ul {
      background-image: url(../../../public/assets/editor/list-ul.svg);
   }

   &-dropdown-item i.ol,
   & i.ol {
      background-image: url(../../../public/assets/editor/list-ol.svg);
   }

   &-dropdown-item i.quote,
   & i.quote {
      background-image: url(../../../public/assets/editor/chat-square-quote.svg);
   }

   &-dropdown-item i.code,
   & i.code {
      background-image: url(../../../public/assets/editor/code.svg);
   }

   & i.bold {
      background-image: url(../../../public/assets/editor/type-bold.svg);
   }

   & i.italic {
      background-image: url(../../../public/assets/editor/type-italic.svg);
   }

   & i.underline {
      background-image: url(../../../public/assets/editor/type-underline.svg);
   }

   & i.strikethrough {
      background-image: url(../../../public/assets/editor/type-strikethrough.svg);
   }

   & i.code {
      background-image: url(../../../public/assets/editor/code.svg);
   }

   & i.link {
      background-image: url(../../../public/assets/editor/link.svg);
   }

   & i.left-align {
      background-image: url(../../../public/assets/editor/text-left.svg);
   }

   & i.center-align {
      background-image: url(../../../public/assets/editor/text-center.svg);
   }

   & i.right-align {
      background-image: url(../../../public/assets/editor/text-right.svg);
   }

   & i.justify-align {
      background-image: url(../../../public/assets/editor/justify.svg);
   }
}

.link-editor {
   display: flex;
   align-items: center;
   position: absolute;
   top: 0;
   left: 0;
   z-index: 4000;
   max-width: 400px;
   width: 100%;
   opacity: 0;
   background-color: #fff;
   box-shadow: 0 5px 10px #0000004d;
   border-radius: 0 0 8px 8px;
   transition: opacity .5s;
   will-change: transform;
   padding: 10px;

   & .link-input {
      font-size: 0.9rem;
      flex-grow: 1;
      display: flex;
      align-items: center;
      margin-left: 5px;

      &> :first-child {
         flex-grow: 1;
      }

      & [role=button] {
         width: 20px;
         height: 20px;
         display: inline-block;
         padding: 6px;
         border-radius: 8px;
         cursor: pointer;
         margin: 0 2px;
         background-color: #eee;
         font-size: 1rem;
      }

      & .link-edit::before {
         padding-left: 2px;
         content: '✎';
      }

      & .link-cancel::before {
         padding-left: 4px;
         content: '✕';
      }

      & .link-confirm::before {
         padding-left: 4px;
         content: '✓';
      }

      & [role=button]:hover {
         background-color: #d9d9d9;
      }
   }

}