.order-warning-address {
   display: flex;
   gap: 30px;

   & div:first-child {
      max-width: 50%;
   }

   @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: stretch;
      gap: 20px;

      & div:first-child {
         max-width: 100%;
      }

      & img {
         max-height: 280px;
         object-fit: cover;
      }
   }

   & img {
      border-radius: 20px;
      width: 100%;
   }

   &__block {
      text-align: left;
   }

   &__block:not(:last-child) {
      margin-bottom: 20px;
   }

   & strong {
      font-weight: 600;
      margin-bottom: 20px;
   }
}

.order-warning {
   display: flex;
   gap: 30px;
   align-items: center;

   @include respond(tabletAndMobile) {
      flex-direction: column;
      gap: 10px;
      text-align: center;
      padding: $mobilePaddingX;
   }

   &__logo {
      font-size: 40px;
      color: $redColor;

      @include respond(tabletAndMobile) {
         font-size: 35px;
      }
   }

   &__text {
      font-weight: 500;
   }

   & .btn {
      margin-left: auto;
      flex-shrink: 0;

      @include respond(tabletAndMobile) {
         margin-left: unset;
      }
   }
}