.products {
   &__categories {
      display: grid;
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
      gap: 25px;
      @media only screen and (min-width: 800px) and (max-width: 1200px) {
         grid-auto-columns: unset;
         grid-auto-flow: unset;
         grid-template-columns: 1fr 1fr;
      }
      @media only screen and (min-width: 601px) and (max-width: 799px) {
         grid-auto-columns: unset;
         grid-auto-flow: unset;
         grid-template-columns: 1fr;
      }
      @include respond(mobile) {
         grid-auto-columns: unset;
         grid-auto-flow: row;
         gap: 30px;
      }
   }

   &__container {
      display: flex;
      gap: 25px;
   }

   &__aside {
      width: 240px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      @include respond(tabletAndMobile) {
         padding: $mobilePaddingX $mobilePaddingX $mobilePaddingX $mobilePaddingX;
         position: fixed;
         inset: 0;
         z-index: 3000;
         width: unset;
         background-color: white;
         overflow-y: scroll;
         &--visible {
            display: flex;
         }
         &--hidden {
            display: none;
         }
      }
   }

   &__aside-title {
      margin-bottom: 30px;
      padding: 7px 35px;
      border-top: 1px solid $separatorColor;
      border-bottom: 1px solid $separatorColor;
      font-size: 1.45rem;
      align-self: center;
   }

   &__reset-filter {
      align-self: center;
      color: $secondaryTextColor;
      text-decoration: none;
      &:hover {
         color: $accentColor;
      }
   }

   &__main {
      flex-grow: 1;
      border-left: 1px solid $separatorColor;
      padding-left: 50px;
      @include respond(tabletAndMobile) {
         border-left: unset;
         padding: 0;
      }
   }
   &__grid-header,
   &__title,
   &__description {
      @include respond(mobile) {
         padding: 0 $mobilePaddingX;
      }
   }

   &__title {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 15px;
      & h1 {
         margin-right: 20px;
      }
      & .tag {
         white-space: nowrap;
         font-size: 0.95rem;
         @include respond(mobile) {
            padding: 4px 8px;
         }
      }
   }
   &__description {
      padding-bottom: 20px;
      border-bottom: 1px solid $separatorColor;
      margin-bottom: 35px;
   }
   &__edit {
      display: grid;
      grid-template-columns: 1fr min-content;
      gap: 10px;
      flex-grow: 1;
      & :first-child {
         grid-row: 1 / 3;
      }
   }

   &__filters-toggle {
      display: none;
      padding: 7px 15px;
      @include respond(tabletAndMobile) {
         display: unset;
      }
   }
   &__grid-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      gap: 5px;
   }

   &__quantity {
      text-align: center;
   }

   &__selection {
      text-align: center;
      margin-bottom: 15px;
   }

   &__cow {
      text-align: center;
      padding: 0px 10px;
      font-size: calc(12rem + 8vw);
      color: #E5E5E5;
      animation: fadeIn 0.15s linear 0.15s forwards;
   }

   &__grid {
      opacity: 0;
      animation: fadeIn 0.15s linear 0.15s forwards;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 25px;
      @include respond(mobile) {
         grid-template-columns: 1fr;
      }
      @media only screen and (min-width: 601px) and (max-width: 830px) {
         grid-template-columns: 1fr 1fr;
      }
      @media only screen and (min-width: 831px) and (max-width: 1024px) {
         grid-template-columns: 1fr 1fr 1fr;
      }
      @include respond(smallDesktop) {
         grid-template-columns: 1fr 1fr;
      }
      @include respond(mediumDesktop) {
         grid-template-columns: 1fr 1fr 1fr;
      }
   }

   & #dropdown-actions,
   & #dropdown-sort {
      border-radius: 25px;
   }

   &__grid-header .dropdown button {
      padding: 7px 15px 7px 18px;
      border-radius: 25px 0 0 25px;
   }

   & .filter {
      display: grid;
      grid-template-columns: 1fr;
      background-color: white;
      margin-bottom: 20px;
      padding: 18px;
      @include respond(mobile) {
         padding: 0px;
         margin-bottom: 30px;
      }

      &__title {
         margin-bottom: 10px;
      }

      & .btn {
         font-size: 0.9rem;
         padding: 6px 10px;
         @include respond(mobile) {
            font-size: 1rem;
         }
      }
   }
}
