.loader {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;

   &--full {
      height: 400px;
   }

   &--page {
      opacity: 0;
      height: 100vh;
      animation: fadeIn 0.15s linear 0.5s forwards;
   }

   &__text {
      font-size: 1.4em;
      color: $accentColor;
      margin-bottom: 25px;
   }

   &__dots {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
   }

   &__dot {
      background: $accentColor;
      display: inline-block;
      border-radius: 50%;
      position: relative;

      &--normal,
      &--full {
         width: 12px;
         height: 12px;
         margin: 0px 2px;
         animation: jump 1s linear infinite;
      }

      &--page {
         width: 20px;
         height: 20px;
         margin: 0px 4px;
         animation: jumpxl 1s linear infinite;
      }
   }

   &__dot-1 {
      animation-delay: 100ms;
   }

   &__dot-2 {
      animation-delay: 200ms;
   }

   &__dot-3 {
      animation-delay: 300ms;
   }
}

@keyframes jump {
   0% {
      bottom: 0px;
   }

   25% {
      bottom: 5px;
   }

   50% {
      bottom: 0px;
   }
}

@keyframes jumpxl {
   0% {
      bottom: 0px;
   }

   25% {
      bottom: 10px;
   }

   50% {
      bottom: 0px;
   }
}