.cart-modal {

   &--isHidden {
      display: none;
   }

   &__title {
      text-align: center;
      margin-bottom: 40px;
   }

   &__main {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow-y: auto;

      /* Firefox */
      &--empty {
         align-items: center;
         justify-content: center;
         gap: 50px;
         padding-bottom: 50px;
      }
   }

   &__start-shopping {
      white-space: nowrap;
      display: flex;
      align-items: center;
      gap: 10px;
   }

   &__show-cart {
      margin-top: 20px;
      flex-shrink: 0;
      min-height: 45px;
   }

   &__group {
      margin-bottom: 30px;
   }

   &__group-title {
      border-bottom: 1px solid $separatorColor;
      padding-bottom: 5px;
      margin-bottom: 10px;
      font-weight: 500;
   }

   &__group-items {
      display: flex;
      flex-direction: column;
      gap: 10px;
   }

   &__item {
      display: flex;
      align-items: center;
      gap: 12px;
      min-height: 28px;

      & .btn {
         padding: 0;
         width: 30px;
         height: 30px;
         flex-shrink: 0;
      }
   }

   &__item-title {
      flex-grow: 1;

      & a {
         text-decoration: none;
         color: $primaryTextColor;
      }

      & a:hover {
         color: $accentColor;
      }
   }

   &__item-quantity {
      text-align: center;
      min-width: 30px;

      @include respond(mobile) {
         min-width: 24px;
      }
   }

   &__item-trash {
      cursor: pointer;
      margin-left: 6px;

      &:hover {
         color: $redColor;
      }
   }
}