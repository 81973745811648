.hero {
   background-color: white;

   & .page-container {
      text-align: center;
      padding: 75px;
      position: relative;

      @include respond(mobile) {
         padding: 50px 25px;
      }
   }

   &__title,
   &__subtitle,
   & h1,
   & h2 {
      text-transform: uppercase;
      line-height: 1.1;
   }

   &__subtitle,
   & h2,
   & .markdown-editor h2 {
      color: $accentColor;
      margin-bottom: 35px;
   }

   &__text {
      font-size: 1.15rem;
      margin-bottom: 35px;
   }

   &__buttons {
      margin-top: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;

      @include respond(mobile) {
         flex-direction: column;
      }
   }

   & .edit-button {
      position: absolute;
      top: 75px;
      right: 15px;

      @include respond(tabletAndMobile) {
         top: 50px;
      }
   }

   & .markdown-editor {

      & h1,
      & h2,
      & h3,
      & p {
         text-align: center;
      }

      & .editor-input {
         min-height: 200px;
         max-height: 300px;
      }
   }
}