.cart-page {
   &__content {
      display: flex;
      gap: 40px;
      align-items: flex-start;

      @include respond(smallDesktop) {
         gap: 20px;
      }

      @include respond(tabletAndMobile) {
         flex-direction: column;
         align-items: stretch;
      }
   }

   &__products {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 20px;
   }

   &__group .tag {
      font-size: 0.9rem;
   }

   &__group-items {
      display: flex;
      flex-direction: column;
      gap: 5px;
   }

   &__group-items>div:not(:last-child) {
      padding-bottom: 10px;
      border-bottom: 1px solid rgba($color: $separatorColor, $alpha: 0.55);
      margin-bottom: 10px;
   }

   &__item {
      display: grid;
      grid-template-areas: "title actions price trash";
      grid-template-columns: auto min-content min-content min-content;
      align-items: center;
      gap: 8px;
      margin-left: 30px;

      @include respond(mobile) {
         grid-template-areas:
            "title title title"
            "actions price trash";
      }

      @include respond(tabletAndMobile) {
         margin-left: 0;
      }

      & .btn {
         width: 30px;
         height: 30px;
         padding: 0;
         flex-shrink: 0;
      }
   }

   &__item-title {
      grid-area: title;
      flex-grow: 1;

      & a {
         color: $primaryTextColor;
         text-decoration: none;
      }

      & a:hover {
         color: $accentColor;
      }
   }

   &__item-actions {
      grid-area: actions;
      display: flex;
      align-items: center;
      gap: 10px;
   }

   &__item-quantity {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      border: 1px solid $accentColor;
      color: $accentColor;
      font-weight: 600;
      flex-shrink: 0;

      @include respond(mobile) {
         width: 30px;
         height: 30px;
      }
   }

   &__item-price {
      grid-area: price;
      min-width: 110px;
      text-align: right;
      font-size: 1.1rem;

      @include respond(tabletAndMobile) {
         min-width: 75px;
      }
   }

   &__item-trash {
      grid-area: trash;
      cursor: pointer;
      margin-left: 15px;

      @include respond(mobile) {
         margin-left: 5px;
      }

      &:hover {
         color: $redColor;
      }
   }

   &__reset {
      align-self: center;
   }

   &__empty {
      text-align: center;
      min-height: 300px;

      & p {
         margin: 50px 0 40px 0;
         font-size: 1.1rem;
      }
   }

   &__start-shopping {
      display: inline-flex;
      align-items: center;
      gap: 10px;
   }

   &__warning-delivery {
      font-size: 0.9rem;
      align-self: flex-start;
      margin-top: 20px;
   }

   &__confirmation {
      display: flex;
      flex-direction: column;
      align-items: center;

      &>p {
         text-align: center;
         margin-bottom: 20px;

         @include respond(mobile) {
            padding: 0 $mobilePaddingX;
         }
      }

      &-btn {
         display: flex;
         gap: 30px;
         margin-top: 50px;
      }
   }

   &__confirmation-title {
      display: flex;
      justify-content: center;
      gap: 20px;

      @include respond(tabletAndMobile) {
         flex-direction: column;
         padding: 0 $mobilePaddingX;
         text-align: center;
         gap: 10px;
      }

      & h2 {
         margin-bottom: 30px;
         border-bottom: 0px solid transparent;
         color: $accentColor;
         font-size: 1.4rem;
      }
   }

   &__confirmation-icon {
      font-size: 1.4rem;
      color: $accentColor;
   }

   &__summary {
      width: 400px;
      top: 138px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include respond(tabletAndMobile) {
         position: unset;
         width: unset;
      }

      & form {
         align-self: stretch;
         display: flex;
         flex-direction: column;
         gap: 8px;
      }

      &-header {
         display: flex;
         justify-content: space-between;
         align-self: stretch;
      }

      &-arrow {
         width: 30px;
         color: grey;
         font-size: 25px;

         & .svg-inline--fa {
            cursor: pointer;

            &:hover {
               color: $primaryTextColor;
            }
         }
      }

      &-title {
         text-align: center;
      }

      &-invited {
         display: flex;
         flex-direction: column;
         gap: 25px;
         text-align: center;

         & p {
            font-size: 0.9rem;
            margin-bottom: 15px;
         }

         & a {
            display: inline-block;
            width: 95%;
            margin-top: 12px;
            font-size: 0.9rem;
         }
      }

      &-or {
         font-size: 1.4rem;
         font-weight: 500;
      }

      &-line {
         margin: 15px 0 15px 0;
         align-self: stretch;
         align-items: center;

         &>p:last-child {
            text-align: right;
         }
      }

      & h3 {
         font-size: 0.95rem;
         text-transform: uppercase;
         font-weight: 600;
         border-bottom: 1px solid $separatorColor;
         text-align: center;
         color: $secondaryTextColor;
         padding-bottom: 8px;
         align-self: stretch;
         margin-top: 10px;
      }

      & .form-select {
         width: 180px;
      }

      &-amount {
         font-weight: 600;
         font-size: 1.3rem;
      }

      &-checkbox {
         margin-top: 30px;
      }

      &-account {
         align-self: center;
         margin: 10px 0 10px 0;
      }

      &-btn {
         margin-top: 20px;
         align-self: center;
      }
   }
}