html {
   font-size: 18px;
   -webkit-text-size-adjust: 100%;
   @include respond(smallDesktop) {
      font-size: 17px;
   }
   @include respond(tablet) {
      font-size: 16px;
   }
   @include respond(mobile) {
      font-size: 15px;
   }
}

body {
   font-family: Poppins, sans-serif;
   color: $primaryTextColor;
   background-color: #FFFFFF;
}

strong {
   font-weight: 600;
}

h1 {
   font-weight: 700;
   font-size: 2.5rem;
   @include respond(mobile) {
      font-size: 2.1rem;
      line-height: 105%;
   }
}

h2 {
   font-weight: 600;
   font-size: 2rem;
}

h3 {
   font-weight: 600;
   font-size: 22px;
}

h4 {
   font-weight: 600;
   font-size: 20px;
}

a {
   color: $accentColor;
}

input,
textarea,
select {
   box-sizing: border-box;
   font-family: Poppins, sans-serif;
}

.form-control {
   font-size: 1rem;
}

.form-floating>label {
   font-size: 15px;
}

.form-check .form-check-input {
   margin-top: 0px;
}

.form-switch .form-check-input {
   height: 2.1em;
   width: 3em;
   margin-left: -4em;
}

.page-container {
   margin: 0 auto;
   max-width: 1600px;
   box-sizing: border-box;
}

.page {
   background-color: $primaryBackgroundColor;
   padding: $defaultPagePadding;
   min-height: 600px;

   @include respond(smallDesktop) {
      padding: $tabletPagePadding;
   }

   @include respond(tablet) {
      padding: $tabletPagePadding;
   }

   @include respond(mobile) {
      padding: $mobilePagePadding;
      min-height: 200px;
   }

   &-title {
      text-align: center;
      margin-bottom: 75px;

      @include respond(tabletAndMobile) {
         padding: 0 25px;
         margin-bottom: 35px;
      }
   }
}

.menu {
   position: fixed;
   inset: 0;
   background-color: rgba(0, 0, 0, 0.5);
   display: flex;
   justify-content: flex-end;
   z-index: 105;
   opacity: 0;
   animation: fadeIn 0.1s linear forwards;

   &__content {
      z-index: 106;
      display: flex;
      flex-direction: column;
      background-color: white;
      padding: 75px 40px 40px 40px;
      position: relative;
      right: -500px;
      top: 0px;
      bottom: 0px;
      width: 430px;
      //transform: translate(600px, 0);
      //animation: slideFromRight 0.2s linear forwards;

      @include respond(mobile) {
         padding: 75px $mobilePaddingX $mobilePaddingX $mobilePaddingX;
         /*width: 100%;*/
         width: 100%;
         /*left: 0px;*/
      }
   }
}


.menu__content.visible {animation:0.2s slideRight ease-in forwards; transition-delay:0;}
.menu__content.invisible {animation:0.1s slideLeft ease-out forwards; transition-delay:0;}

/* Animations pour le menu slide */
@keyframes slideRight {
	from {right:-500px;}
	to {right:0px;}
}
@-webkit-keyframes slideRight {
	from {right:-500px;}
	to {right:0px;}
}
@keyframes slideLeft {
	from {right:0px;}
	to {right:-500px;}
}
@-webkit-keyframes slideLeft {
	from {right:0px;}
	to {right:-500px;}
}


@keyframes slideFromRight {
   100% {
      transform: translate(0, 0);
   }
}

@keyframes fadeIn {
   100% {
      opacity: 1;
   }
}

.generic-card {
   border-radius: 25px;
   background-color: white;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
   overflow: hidden;

   @include respond(mobile) {
      border-radius: unset;
      box-shadow: unset;
   }
}

.dropdown-item {
   width: auto;
}

.react-select-container {
   width: 100%;

   & .react-select__control {
      font-size: 1rem;
      font-weight: 400;
      //line-height: 1.5;
      border: var(--bs-border-width) solid var(--bs-border-color);
      border-radius: var(--bs-border-radius);
      box-shadow: none;

      &:hover {
         border-color: var(--bs-border-color);
      }

      &--menu-is-open {
         box-shadow: 0 0 0 4px #d2e1cf;

         &:hover {
            border-color: #8fbb89;
         }
      }

      &--is-focused {
         border-color: #8fbb89;
         box-shadow: 0 0 0 4px #d2e1cf;
      }
   }

   & .react-select__option {
      &--is-focused {
         background-color: rgba($color: $accentColor, $alpha: 0.2);

         &:active {
            background-color: rgba($color: $accentColor, $alpha: 0.3);
         }
      }

      &--is-selected {
         background-color: $accentColor;

         &:active {
            background-color: rgba($color: $accentColor, $alpha: 0.9);
         }
      }
   }

   & .react-select__dropdown-indicator {
      color: #333b40;
   }

   & .react-select__menu {
      z-index: 3000;
   }
}

.two-columns {
   display: flex;
   justify-content: space-between;
   align-items: flex-start;
   gap: 40px;

   &--vertical-centered {
      align-items: center;
   }
}

.two-columns-grid {
   display: grid;
   grid-template-columns: 1fr 2fr;
   gap: 10px;
}

.modal-body {
   padding: 40px;
   text-align: center;

   @include respond(mobile) {
      padding: 20px;
   }

   & h4 {
      margin-bottom: 15px;
   }

   .form-label {
      margin-bottom: 20px;
      //line-height: 120%;
      display: inline-block;
   }
}

.modal-footer {
   justify-content: center;
}

.btn-rounded {
   border-radius: 25px;
}

button {
   color: $accentColor;
   border: none;
   background: none;
   cursor: pointer;
   font-size: 1rem;
   font-family: Poppins, sans-serif;
   padding: 0;
   margin: 0;
   -webkit-tap-highlight-color: transparent;
}

.quantity-selector {
   display: flex;
   align-items: center;
   gap: 4px;
   padding: 0 6px;
   background-color: $accentColor;
   color: white;
   border-radius: 50px;
   font-weight: 500;
   & span {
      min-width: 28px;
      text-align: center;
      @media only screen and (max-width: 375px) {
         min-width: 22px;
      }
   }
   & button {
      color: white;
      padding: 8px 12px;
      @media only screen and (max-width: 375px) {
         padding: 8px;
      }
   }
}

.white-block {
   background-color: white;
   padding: 30px;

   @include respond(mobile) {
      padding: 20px;
   }

   & h2 {
      font-size: 1.45rem;
      border-bottom: 1px solid $separatorColor;
      padding-bottom: 0.5rem;
      margin-bottom: 1.4rem;
      display: inline-block;
   }

   &.markdown h2 {
      margin-bottom: 2rem;
   }
}

.disabled-text {
   color: $secondaryTextColor;
}

.warning-text {
   color: $redColor;
}

.strike-text {
   text-decoration: line-through;
}

.message-text {
   text-align: center;
   padding: 50px 20px;
   font-size: 1.25rem;
   color: $secondaryTextColor;
}

.breakable-text {
   display: block;
   overflow: hidden;
   text-overflow: ellipsis;
}

a.secondary-link {
   color: $secondaryTextColor;
   text-decoration: none;

   &:hover {
      color: $accentColor;
   }
}

.markdown,
.markdown-editor {
   font-size: 1rem;

   & strong {
      font-weight: 600;
   }

   & p:not(:last-child) {
      margin-bottom: 15px;
   }

   & h2,
   & h3,
   & h4,
   & h5,
   & h6 {
      margin-bottom: 10px;
   }

   &:not(.white-block) h2 {
      font-size: 2rem;
   }

   & h3 {
      font-size: 22px;
   }

   & h4,
   & h5,
   & h6 {
      font-size: 20px;
   }

   & ul {
      list-style: disc;
   }

   & ol {
      list-style: decimal;
   }

   & li {
      margin-left: 25px;
   }

   & ul>li:last-child {
      margin-bottom: 20px;
   }
}

.edit-button {
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: $dark;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
   color: white;
   width: 2.7rem;
   height: 2.7rem;
   font-size: 1.4rem;
   cursor: pointer;
   border-radius: 10px;
   flex-shrink: 0;

   &--disabled {
      background-color: $secondaryTextColor;
      cursor: unset;
      color: #ddd;
   }
}

.icon {
   &--primary {
      color: $accentColor;
   }

   &--secondary {
      color: $secondaryTextColor;
   }

   &--warning {
      color: orange;
   }

   &--danger {
      color: $redColor;
   }
}

table {
   width: 100%;
}

th,
td {
   vertical-align: middle;
   padding: 0.4rem;
   border: 1px solid rgba($color: $separatorColor, $alpha: 0.7);
}

th {
   text-align: left;
   font-weight: 600;
   background-color: rgba($color: $primaryBackgroundColor, $alpha: 0.7);
}

.edit-modal {
   & h2 {
      font-size: 1.45rem;
      text-align: center;
   }

   & h3 {
      font-size: 20px;
      margin: 15px 0;
   }

   & .markdown-editor {
      & .editor-input {
         min-height: 200px;
         max-height: 200px;
      }
   }

   & .modal-footer {
      min-height: 51px;
   }

   & .modal-body {
      padding-bottom: 20px;
      text-align: left;

      @include respond(tabletAndMobile) {
         padding: 20px;
      }
   }

   & .react-select__control,
   & .react-select__value-container {
      height: 100%;
   }

   & .react-select__value-container {
      min-height: 56px;
      padding-top: 22px;
   }

   & .react-select__floating-label {
      position: absolute;
      top: 6px;
      left: 10px;
      font-size: 13px;
      color: rgba(var(--bs-body-color-rgb), 0.65);
   }

   &__success {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 25px;
      min-height: 300px;
      font-size: 1.4rem;
      font-weight: 600;
      color: $accentColor;
   }

   &__columns {
      display: flex;
      align-items: flex-start;
      gap: 20px;

      @include respond(tabletAndMobile) {
         flex-direction: column;
         align-items: stretch;
      }

      &>div:first-child {
         flex-grow: 1;
      }

      &>div:last-child {
         width: 300px;
         flex-shrink: 0;
         text-align: center;

         @include respond(tabletAndMobile) {
            flex-grow: 1;
            width: unset;
         }
      }
   }

   &__grid {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      gap: 8px;

      @include respond(tabletAndMobile) {
         grid-template-columns: 1fr;
      }
   }

   &__colspan {
      grid-column: 1 / 4;

      @include respond(tabletAndMobile) {
         grid-column: 1 / 2;
      }
   }
}