.legal {
   text-align: center;
   & .page-container {
      @include respond(mobile) {
         padding: 0 $mobilePaddingX;
      }
   }
   &__content {
      max-width: 600px;
      margin: 0 auto;
   }
   & h2 {
      margin-bottom: 10px;
   }
   & p {
      margin-bottom: 50px;
   }
   & strong {
      font-weight: 600;
   }
}
