.admin-newsletter {
    & ol {
        list-style-type: decimal;
        padding-left: 40px;
    }
    & li {
        margin-bottom: 8px;
    }
    & .white-block {
        margin-bottom: 20px;
    }
}