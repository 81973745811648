.header {
   z-index: 100;
   background-color: white;
   position: fixed;
   top: 0;
   left: 0;
   right: 0;

   &--sticky {
      box-shadow: 0 8px 8px rgba(0, 0, 0, 0.12);

      @include respond(tabletAndMobile) {
         & .header__content {
            height: 55px;
         }

         & .header__logo img {
            width: unset;
            height: 48px;
         }
      }
   }

   &__closed,
   &__admin {
      color: white;
      padding: 7px 20px;
      text-align: center;
      @include respond(tabletAndMobile) {
         padding: 3px 20px;
      }
      & p {
         font-size: 0.95rem;
         font-weight: 500;
         line-height: 1.1;
      }

      & .page-container {
         min-height: 32px;
         flex-grow: 1;
         display: flex;
         align-items: center;
         justify-content: center;
         gap: 20px;
      }

      & .btn {
         display: flex;
         align-items: center;
         gap: 5px;
         padding: 0.2rem 0.8rem;
         flex-shrink: 0;
      }
   }

   &__closed {
      background-color: $redColor;

      & .svg-inline--fa {
         font-size: 1.3rem;
      }

      & .btn {
         padding: 0.15rem 0.6rem;
         font-size: 0.75rem;
      }
   }

   &__admin {
      background-color: $secondaryBackgroundColor;

      &-buttons {
         display: flex;
         gap: 10px;
         align-items: center;
      }

      & .btn span {
         @include respond(tabletAndMobile) {
            display: none;
         }
      }
   }

   &__content {
      height: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 35px;
      flex-grow: 1;
      padding: 0 20px;

      @include respond(tabletAndMobile) {
         height: 80px;
         padding: 0 16px 0 10px;
         gap: 10px;
      }
   }

   &__logo {
      display: flex;
      align-items: center;

      & img {
         width: 100%;
         max-width: 300px;

         @include respond(tabletAndMobile) {
            max-width: 240px;
         }
      }
   }

   &__menu {
      display: none;

      @include respond(tablet) {
         display: unset;
         position: fixed;
         inset: 0;
         background-color: rgba(0, 0, 0, 0.5);
         display: flex;
         justify-content: flex-end;
         z-index: 105;
         opacity: 0;
         animation: fadeIn 0.1s linear forwards;
      }

      &--closed {
         display: none;
      }
   }

   &__nav {
      display: flex;
      align-items: center;
      gap: 1.8rem;

      &-link {
         & span:hover {
            color: $accentColor;
         }

         & span {
            display: none;
         }

         @include respond(tabletAndMobile) {
            & span {
               display: block;
            }

            & .svg-inline--fa {
               display: none;
            }
         }
      }

      @include respond(tabletAndMobile) {
         & .header__cart-number {
            display: none;
         }
         &--opened {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 3.8vh;
            position: fixed;
            background-color: white;
            z-index: 3000;
            transform: translate(500px, 0);
            animation: slideFromRight 0.2s linear forwards;
            & a,
            & button {
               font-size: 24px;
            }
         }
         &--closed {
            display: none;
         }
      }

      @include respond(tablet) {
         &--opened {
            padding: 40px;
            top: 0;
            bottom: 0;
            right: 0;
            width: 430px;
         }
      }

      @include respond(mobile) {
         &--opened {
            padding: 20px;
            inset: 0;
         }
      }

      &-btn-close {
         display: none;
         @include respond(tabletAndMobile) {
            display: block;
         }
      }

   }

   &__burger {
      display: none;
      flex-shrink: 0;

      @include respond(tabletAndMobile) {
         display: flex;
         gap: 10px;
      }

      & button,
      & a {
         font-size: 23px;
         padding: 10px;
      }
   }

   &__cart {
      position: relative;
      cursor: pointer;

      &-icon {
         &:hover {
            color: $accentColor;
         }

         &--active {
            color: $accentColor;
         }
      }

      &-number {
         position: absolute;
         border-radius: 50%;
         min-width: 22px;
         height: 22px;
         background-color: $redColor;
         top: -9px;
         right: -10px;
         color: white;
         display: flex;
         justify-content: center;
         align-items: center;
         font-size: 10px;
         font-weight: 600;
         padding-top: 1px;

         @include respond(tabletAndMobile) {
            top: 5px;
            right: 0px;
            min-width: 20px;
            height: 20px;
            font-size: 9px;
         }
      }
   }

   &__submenu {
      position: absolute;
      left: 0;
      right: 0;
      background-color: white;
      border-top: 1px solid rgba($color: $separatorColor, $alpha: 0.6);
      border-bottom: 1px solid rgba($color: $separatorColor, $alpha: 0.6);
      padding: 20px;
      display: flex;
      gap: 40px;
      justify-content: center;
      opacity: 0;

      @include respond(tabletAndMobile) {
         display: none;
      }

      &--visible {
         animation: 0.2s fadeIn 0.1s linear forwards;
      }

      &--hidden {
         //display: none;
         opacity: 0;
      }
   }

   & a,
   & button {
      text-decoration: none;
      color: $primaryTextColor;
      font-weight: 500;
      //line-height: inherit;
   }

   & a.active {
      color: $accentColor;
   }

   & a:hover {
      color: $accentColor;
   }
}