.footer {
   background-color: $secondaryBackgroundColor;
   color: white;

   & p,
   & a {
      font-size: 0.95rem;
   }

   & a {
      text-decoration: none;
      color: white;

      &:hover {
         color: #C5C5C5;
      }
   }

   & h4 {
      margin-bottom: 0.5rem;
   }

   &__container,
   &__bottom {
      padding: 0 20px;

      @include respond(mobile) {
         padding: 0 $mobilePaddingX;
      }
   }



   &__blocks {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1rem;
      padding: 1.1rem 0;

      @include respond(mobile) {
         grid-template-columns: 1fr;
      }
   }

   &__block {
      display: flex;
      justify-content: center;
      gap: 20px;
      padding: 15px 20px;
      background-color: #262626;
      border: 1px solid rgba(255, 255, 255, 0.12);
      border-radius: 5px;
      align-items: center;
      text-align: center;

      @include respond(tabletAndMobile) {
         gap: 10px;
         padding: 10px;
      }

      @include respond(mobile) {
         justify-content: flex-start;
         text-align: left;
      }
   }

   &__fa {
      font-size: 2rem;
      color: $accentColor;

      @include respond(tabletAndMobile) {
         font-size: 1.6rem;
      }
   }

   &__main {
      display: flex;
      justify-content: space-between;
      gap: 4vw;

      @media only screen and (max-width: 900px) {
         flex-direction: column;
         gap: 25px;
      }
   }

   &__company {
      flex-shrink: 0;
   }

   &__address {
      margin-bottom: 25px;
   }

   &__pages {
      display: flex;
      justify-content: flex-end;
      gap: 4vw;

      @media only screen and (max-width: 900px) {
         justify-content: flex-start;
      }

      @include respond(mobile) {
         flex-direction: column;
         gap: 25px;
      }
   }

   &__links {
      display: flex;
      flex-direction: column;
      gap: 4px;

      @include respond(tabletAndMobile) {
         gap: 1px;
      }
   }

   &__separator {
      border-bottom: 1px solid #404040;
      margin: 20px 0;
   }

   &__bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
      gap: 12px;

      & p,
      & a {
         font-size: 0.9rem;
      }

      & :nth-child(3) {
         flex-shrink: 0;
      }

      & .two-columns {
         gap: 12px;

         @include respond(mobile) {
            flex-direction: column;
            align-items: center;
            gap: 0;

            & :nth-child(2) {
               display: none;
            }
         }
      }

      @include respond(mobile) {
         flex-direction: column-reverse;
         align-items: center;
         gap: 10px;

         & p,
         & a {
            font-size: 13px;
            text-align: center;
         }
      }
   }

   &__social-network {
      display: flex;
      gap: 20px;

      & img {
         @include respond(mobile) {
            width: 22px;
            height: 25px;
         }

         &:hover {
            opacity: 0.8;
         }
      }
   }
}