.account-creation {
   &__content {
      max-width: 580px;
      margin: 0 auto;
      @include respond(mobile) {
         max-width: unset;
      }
   }
   & input {
      margin-top: 8px;
   }
   &__group {
      margin-bottom: 40px;
   }
   & h2 {
      border: unset;
      font-size: 1.3rem;
      margin-bottom: 10px;
      padding: 0;
   }
   &__footer {
      margin-top: 30px;
      font-size: 0.9rem;
      color: $secondaryTextColor;
   }
   & .user-account-modal {
      margin-top: 20px;
      text-align: center;
      @include respond(mobile) {
         text-align: left;
         padding: 0 25px;
         font-size: 0.9rem;
      }
   }
   & .error-message {
      margin-bottom: 15px;
   }
}
