.lastnews {
   position: relative;
   padding-top: 60px;
   background-color: white;
   &__logo {
      position: absolute;
      top: 0;
      left: calc(50% - 60px);
      width: 120px;
   }
   &__container {
      padding: 80px 0;
      background-color: $primaryBackgroundColor;
      text-align: center;
   }

   &__title {
      text-transform: uppercase;
      margin-bottom: 30px;
      @include respond(mobile) {
         padding: 0 20px;
      }
   }

   &__articles {
      display: flex;
      align-items: stretch;
      justify-content: center;
      gap: 3vw;
      padding: 0 50px;
      @include respond(tabletAndMobile) {
         flex-direction: column;
         gap: 30px;
      }
      @include respond(mobile) {
         padding: 0;
      }
   }
   &__articles .card {
      width: 30%;
      @include respond(tabletAndMobile) {
         width: 100%;
      }
   }
}
