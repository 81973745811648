//@import "../../node_modules/bootstrap/scss/bootstrap";

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
$primary: $accentColor;
$secondary: $secondaryColor;
$carousel-control-opacity: 0.6;
$carousel-control-hover-opacity: 1;
$carousel-indicator-width: 50px;
$carousel-indicator-height: 4px;
$carousel-indicator-opacity: 0.6;
$dropdown-font-size: 18px;
$dropdown-item-padding-y: 10px;
$btn-font-size: 1rem;
$btn-color: white;
$btn-font-weight: 500;
$btn-padding-y: 0.4rem;
$btn-padding-x: 1.4rem;
$btn-padding-x-sm: 1.1rem;
$btn-padding-x-lg: 1.4rem;
$form-check-input-width: 1.2rem;
$form-check-padding-start: $form-check-input-width + .5rem;
$form-check-input-border: 1px solid rgba(black, 0.5);
$accordion-button-active-bg: tint-color($accentColor, 95%);
$form-range-track-bg: $secondaryColor;
$dropdown-link-active-color: $accentColor;
$dropdown-link-active-bg: white;
$caret-width: 0.5em;
$caret-vertical-align: $caret-width * 0.1;
$caret-spacing: $caret-width * 1.75;
$modal-sm: 450px;
$modal-md: 750px;
$modal-lg: 1200px;
$modal-xl: 1340px;
$pagination-font-size: 1rem;
$pagination-padding-y: 0.6rem;
$pagination-padding-x: 1rem;
//$zindex-dropdown: 100;
//$zindex-sticky: 102;
//$zindex-fixed: 103;
//$zindex-modal-backdrop: 3 !important; // fix for ng-bootstrap 3.2.0 todo: remove if fixed
//$zindex-modal: 4;

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/variables-dark";

// 4. Include any default map overrides here
/*$theme-colors: (
    "primary": $accentColor,
    "secondary": $secondaryColor,
);`*/

// 5. Include remainder of required parts
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/utilities/api";
@import "../../node_modules/bootstrap/scss/helpers";
@import "../../node_modules/bootstrap/scss/helpers/visually-hidden";
@import "../../node_modules/bootstrap/scss/root";

// Optional
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/forms/floating-labels";
@import "../../node_modules/bootstrap/scss/forms/form-check";
@import "../../node_modules/bootstrap/scss/forms/form-control";
@import "../../node_modules/bootstrap/scss/forms/form-range";
@import "../../node_modules/bootstrap/scss/forms/form-select";
@import "../../node_modules/bootstrap/scss/forms/form-text";
@import "../../node_modules/bootstrap/scss/forms/input-group";
@import "../../node_modules/bootstrap/scss/forms/labels";
@import "../../node_modules/bootstrap/scss/forms/validation";
@import "../../node_modules/bootstrap/scss/accordion";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/pagination";

//@import "../../node_modules/bootstrap/scss/reboot";
//@import "../../node_modules/bootstrap/scss/type";
//@import "../../node_modules/bootstrap/scss/images";
//@import "../../node_modules/bootstrap/scss/code";
//@import "../../node_modules/bootstrap/scss/grid";