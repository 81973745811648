.filter {
   display: flex;
   gap: 10px;
   align-items: center;
   justify-content: center;

   &__title {
      font-weight: 600;
      font-size: 20px;
      padding-right: 10px;
   }

   & .two-columns :last-child {
      font-weight: 600;
   }

   &__input {
      width: 60px;
      text-align: right;
   }

   &__show-more {
      text-align: center;
      color: $secondaryTextColor;
      text-decoration: none;

      &:hover {
         color: $accentColor;
      }
   }
}