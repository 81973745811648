.user-info {
   & .white-block {
      margin-bottom: 20px;
   }
   & .error-message {
      margin: 0 0 15px 0;
      font-size: 14px;
   }
   &__accept-mailing,
   & .password-strength {
      margin: 20px 0 30px 0;
   }
   .form-check-input[disabled] ~ .form-check-label,
   .form-check-input:disabled ~ .form-check-label {
      color: $primaryTextColor;
      opacity: 1;
   }
   &__delete {
      text-align: center;
      margin-top: 20px;
      @include respond(mobile) {
         padding: 0 25px;
         font-size: 14px;
      }
      & a {
         color: $secondaryTextColor;
         text-decoration: none;
         &:hover {
            color: $redColor;
         }
      }
   }
   &__delete-checkbox {
      margin-top: 20px;
      text-align: left;
      font-weight: 500;
   }
}
