.contact {
   & .white-block {
      margin-bottom: 20px;
   }
   &__content {
      @include respond(smallDesktop) {
         flex-direction: column;
         align-items: stretch;
         gap: 0;
      }
      @include respond(tabletAndMobile) {
         flex-direction: column;
         align-items: stretch;
         gap: 0;
      }
      & > div:first-child {
         flex-grow: 1;
      }
      & > div:last-child {
         width: 32%;
         min-width: 400px;
         @include respond(smallDesktop) {
            min-width: unset;
            width: unset;
         }
         @include respond(tabletAndMobile) {
            min-width: unset;
            width: unset;
         }
      }
      & form {
         display: flex;
         flex-direction: column;
         gap: 8px;
      }
   }
   & .order-warning-address {
      flex-direction: column-reverse;
      align-items: flex-start;
      & > div:first-child {
         display: none;
      }
   }

   &__success {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      min-height: 300px;
      text-align: center;
      @include respond(smallDesktop) {
         min-height: unset;
      }
      @include respond(tabletAndMobile) {
         min-height: unset;
      }
      & h3,
      & .svg-inline--fa {
         color: $accentColor;
      }
      & h3 {
         font-size: 1.4rem;
         font-weight: 600;
      }
      & .svg-inline--fa {
         font-size: 3.5rem;
      }
   }
}
