.image-selector {
   &__add-photo {
      position: relative;
      & img {
         width: 100%;
         max-height: 320px;
         object-fit: scale-down;
         border: 1px solid $separatorColor;
      }
      & input[type="file"] {
         position: absolute;
         inset: 0;
         opacity: 0;
         cursor: pointer;
      }
   }
   &__input2 {
      display: none;
   }
   &__actions {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      gap: 8px;
   }
}
