.admin-settings {
   &__blocks {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      @include respond(tabletAndMobile) {
         grid-template-columns: 1fr;
      }
      & .disabled-text {
         font-size: 0.9rem;
         margin: 15px 0;
      }
   }

   &__closed {
      color: $redColor;
      font-weight: 500;
   }
   &__closing form {
      display: grid;
      grid-template-columns: min-content min-content auto;
      align-items: center;
      gap: 15px;
      width: 100%;
      & > div:last-child {
         text-align: right;
      }
      @include respond(mediumDesktop) {
         grid-template-columns: 1fr;
      }
      @include respond(smallDesktop) {
         grid-template-columns: 1fr;
      }
      @include respond(tabletAndMobile) {
         grid-template-columns: 1fr;
         & > div:last-child {
            text-align: left;
         }
      }
   }

   & .form-label {
      margin: 0;
   }

   & .two-columns {
      gap: 12px;
      align-items: center;
   }
}
