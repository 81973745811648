.account-confirmation {
   &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      max-width: 540px;
      min-height: 130px;
      margin: 0 auto;
      & h2 {
         border: none;
      }
   }
}
