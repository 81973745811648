.banner {
   position: relative;
   //min-height: 150px;
   height: calc(190px + 25vw);
   max-height: 520px;

   &__logo-bio {
      height: 12vw;
      max-height: 70px;
      position: absolute;
      top: 50px;
      right: 50px;
      z-index: 2;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);

      @include respond(tabletAndMobile) {
         top: 20px;
         right: 20px;
      }
   }

   & .edit-button {
      position: absolute;
      bottom: 60px;
      left: calc(50% - 25px);
      z-index: 2;
      border: 1px solid rgba(255, 255, 255, 0.5);
   }

   & .carousel-item {
      & img {
         height: calc(190px + 25vw);
         max-height: 520px;
      }
   }
}