.search {
    & .loader {
        margin: 50px 0;
    }
    &__field-container {
        position: relative;
        width: 60%;
        min-width: 680px;
        margin: 0 auto 50px auto;
        @include respond(tabletAndMobile) {
            width: 90%;
            min-width: unset;
        }
        & .btn {
            position: absolute;
            top: 6px;
            bottom: 6px;
            right: 6px;
            border-radius: 100px;
            @include respond(mobile) {
                padding: 6px 11px; 
            }
        }
    }
    &__field {
        width: 100%;
        border-radius: 100px;
        padding: 20px 35px; 
        @include respond(mobile) {
            padding: 12px 17px; 
        }
    }
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-decoration {
        -webkit-appearance: none;
        appearance: none;
    }

    h2 {
        text-align: center;
        font-size: 1.2em;
    }

    &__results {
        display: flex;
        flex-direction: column;
        gap: 14px;
        margin: 60px auto;
        width: 80%;
        @include respond(tabletAndMobile) {
            width: 100%;
            min-width: unset;
            margin: 30px auto;
        }
    }
    & .two-columns {
        gap: 20px;
    }
    &__result {
        background-color: white;
        padding: 22px;
        cursor: pointer;
        transition: box-shadow 0.125s;
        @include respond(mobile) {
            padding: 16px;
        }
        &:hover {
            box-shadow: 0 0px 20px rgba(0, 0, 0, 0.08);
        }
        & a {
            text-decoration: none;
            margin-right: 10px;
        }
        & span {
            background-color: rgb(225, 225, 225);
            border-radius: 50px;
            display: inline-block;
            padding: 2px 10px;
            font-size: 0.75rem;
            text-transform: uppercase;
            color: rgb(100, 100, 100);
        }
        & h3 {
            font-size: 1em;
            margin-bottom: 6px;
        }
        & .svg-inline--fa {
            color: rgb(200, 200, 200);
            font-size: 1.25rem;
        }
        &:hover .svg-inline--fa {
            color: $accentColor;
        }
    }
}