.close-button {
    position: absolute;
    opacity: 0.4;
    top: 10px;
    right: 16px;
    font-size: 25px;
    padding: 10px;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
    & .svg-inline--fa {
        color: $primaryTextColor;
    }
}