.edit-banner-modal {
   & .edit-modal__grid {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
      margin-top: 20px;
      @include respond(mobile) {
         grid-template-columns: 1fr;
         gap: 32px;
      }
   }

   & .image-selector__add-photo img {
      width: 100%;
      height: 220px;
      @include respond(mobile) {
         height: 180px;
      }
   }
}
