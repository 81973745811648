.account-reinitialisation {
   &__content {
      max-width: 640px;
      margin: 0 auto;
      text-align: center;
   }
   & .password-strength {
      margin: 25px 0;
   }
   & .user-account-modal {
      font-size: 20px;
      font-weight: 600;
   }
   & form {
      text-align: left;
   }

   & p {
      margin-bottom: 20px;
   }
}
