.admin-customers {

    & .white-block {
        margin-bottom: 20px;
    }
 
    & form {
         display: flex;
         align-items: center;
         justify-content: center;
         gap: 8px;
         font-size: 24px;
         margin-bottom: 25px;
         @include respond(tabletAndMobile) {
            justify-content: center;
         }
         & input {
            width: 65%;
            max-width: 400px;
         }
         & .btn {
            padding: 4px 12px;
         }
         & .svg-inline--fa {
            cursor: pointer;
        }
    }

    & table th:last-child {
        width: 50px;
    }

}