.about-us {
   &__pages {
      display: grid;
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
      gap: 30px;

      @include respond(smallDesktop) {
         grid-auto-columns: unset;
         grid-auto-flow: unset;
         grid-template-columns: 1fr 1fr;
      }
      @include respond(tablet) {
         grid-auto-columns: unset;
         grid-auto-flow: unset;
         grid-template-columns: 1fr;
      }
      @include respond(mobile) {
         grid-auto-columns: unset;
         grid-auto-flow: row;
         gap: 30px;
      }
   }

   &__block-container {
      margin-bottom: 50px;
      @include respond(tabletAndMobile) {
         flex-direction: column-reverse;
         gap: 10px;
         align-items: stretch;
         margin-bottom: 30px;
      }
   }

   &__block {
      flex-grow: 1;
      &--top,
      &--bottom {
         display: flex;
         gap: 50px;
         text-align: center;
         @include respond(tabletAndMobile) {
            gap: 20px;
         }
         & li {
            text-align: left;
         }
      }
      &--top {
         flex-direction: column;
      }
      &--bottom {
         flex-direction: column-reverse;
         @include respond(tabletAndMobile) {
            flex-direction: column;
         }
      }
      &--right,
      &--left {
         & h2 {
            max-width: 55%;
         }
         @include respond(tabletAndMobile) {
            display: flex;
            flex-direction: column;
            gap: 20px;
            text-align: center;
            & li {
               text-align: left;
            }
            & h2 {
               max-width: 100%;
            }
         }
      }
      &--no-image {
         & h2 {
            max-width: 100%;
         }
      }
   }

   &__image {
      &--top,
      &--bottom {
         text-align: center;
         & img {
            width: 100%;
         }
         &.about-us__image--tiny img {
            max-height: 150px;
            @include respond(tablet) {
               max-height: 125px;
            }
            @include respond(mobile) {
               max-height: 100px;
            }
         }
         &.about-us__image--small img {
            max-height: 220px;
            @include respond(tablet) {
               max-height: 190px;
            }
            @include respond(mobile) {
               max-height: 150px;
            }
         }
         &.about-us__image--medium img {
            max-height: 320px;
            @include respond(tablet) {
               max-height: 240px;
            }
            @include respond(mobile) {
               max-height: 190px;
            }
         }
         &.about-us__image--large img {
            max-height: 430px;
            @include respond(tablet) {
               max-height: 300px;
            }
            @include respond(mobile) {
               max-height: 220px;
            }
         }
         &.about-us__image--cover img {
            object-fit: cover;
         }
         &.about-us__image--contain img {
            object-fit: contain;
         }
      }

      &--left,
      &--right {
         &.about-us__image--tiny {
            max-width: 14%;
            @include respond(tabletAndMobile) {
               max-width: unset;
               width: 100%;
            }
         }
         &.about-us__image--small {
            max-width: 20%;
            @include respond(tabletAndMobile) {
               max-width: unset;
               width: 100%;
            }
         }
         &.about-us__image--medium {
            max-width: 30%;
            @include respond(tabletAndMobile) {
               max-width: unset;
               width: 100%;
            }
         }
         &.about-us__image--large {
            max-width: 40%;
            @include respond(tabletAndMobile) {
               max-width: unset;
               width: 100%;
            }
         }
         & img {
            width: 100%;
         }
         @include respond(tabletAndMobile) {
            &.about-us__image--cover img {
               object-fit: cover;
            }
            &.about-us__image--contain img {
               object-fit: contain;
            }
         }
         @include respond(tablet) {
            &.about-us__image--tiny img {
               max-height: 125px;
            }
            &.about-us__image--small img {
               max-height: 190px;
            }
            &.about-us__image--medium img {
               max-height: 240px;
            }
            &.about-us__image--large img {
               max-height: 300px;
            }
         }
         @include respond(mobile) {
            &.about-us__image--tiny img {
               max-height: 100px;
            }
            &.about-us__image--small img {
               max-height: 150px;
            }
            &.about-us__image--medium img {
               max-height: 190px;
            }
            &.about-us__image--large img {
               max-height: 220px;
            }
         }
      }
      &--left {
         margin: 0 35px 35px 0;
         float: left;
         @include respond(tabletAndMobile) {
            margin: 0;
            float: unset;
         }
      }
      &--right {
         margin: 0 0 35px 35px;
         float: right;
         @include respond(tabletAndMobile) {
            margin: 0;
            float: unset;
         }
      }
   }
   & .button-card {
      margin-bottom: 50px;
   }
   &__edit-buttons {
      display: flex;
      flex-direction: column;
      gap: 10px;
      @include respond(tabletAndMobile) {
         flex-direction: row;
         align-self: stretch;
         justify-content: center;
      }
   }
}
