.newsletter {
    padding: 40px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    & h2, & .svg-inline--fa  {
        color: $tertiaryTextColor;
    }

    & h2 {
        font-weight: 500;
        font-size: 1.5rem;
    }

    & .svg-inline--fa {
        font-size: 4rem;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 85px;
    }

    &__field-container {
        position: relative;
        width: 420px;
        @include respond(mobile) {
            width: 340px;
        }
        & .btn {
            position: absolute;
            top: 6px;
            bottom: 6px;
            right: 6px;
            border-radius: 100px;
            @include respond(mobile) {
                padding: 6px 11px; 
            }
        }
    }
    &__field {
        width: 100%;
        border-radius: 100px;
        padding: 16px 35px; 
        @include respond(mobile) {
            padding: 12px 17px; 
        }
    }
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-decoration {
        -webkit-appearance: none;
        appearance: none;
    }

    &__success {
        text-align: center;
        padding: 0 20px;
    }

}
