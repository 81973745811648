.user-connect {
   display: flex;
   flex-direction: column;
   flex-grow: 1;
   & form {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 10px;
   }
   & .btn {
      align-self: flex-start;
      margin-top: auto;
   }
   & .error-message {
      margin-bottom: 15px;
   }
}
