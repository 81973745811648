.button-card {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 25px;
   background-color: $accentColor;
   color: white;
   padding: 1.5rem 10px;
   cursor: pointer;
   &--left {
      flex-direction: row;
      gap: 50px;
      @include respond(mobile) {
         gap: 20px;
      }
   }

   & .svg-inline--fa {
      font-size: 6rem;
   }

   &__title {
      font-weight: 600;
      font-size: 1.2rem;
      text-align: center;
      //line-height: 130%;
   }
}
