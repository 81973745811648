.order-details {
   & .error-message {
      margin-bottom: 20px;
   }

   &__header {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin-bottom: 50px;

      @include respond(tabletAndMobile) {
         flex-direction: column;
      }

      & .tag {
         font-size: 1.1rem;
      }
   }

   &__main {
      display: flex;
      align-items: flex-start;
      gap: 40px;

      @include respond(tabletAndMobile) {
         align-items: stretch;
         gap: 20px;
         flex-direction: column-reverse;
      }

      &>section:last-child {
         flex-grow: 1;
      }

      &>div:first-child {
         display: grid;
         gap: 20px;
         grid-template-columns: 1fr;
         width: 33%;
         min-width: 350px;
         flex-shrink: 0;

         @include respond(tabletAndMobile) {
            width: unset;
            min-width: unset;
         }

         @include respond(tablet) {
            grid-template-columns: 1fr 1fr;
         }
      }
   }

   &__picto {
      width: 28px;
      height: 28px;
      margin-right: 10px;
   }

   &__group {
      margin-bottom: 40px;

      & .two-columns {
         @include respond(tabletAndMobile) {
            flex-direction: column;
            gap: 0;
         }
      }
   }

   &__select {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

   }


   & tr {
      &>td:first-child {
         width: 35px;
      }

      &>td:nth-child(2) {
         @media only screen and (max-width: 400px) {
            word-break: break-word;
         }
      }

      &>td:nth-child(3),
      &>th:nth-child(3) {
         width: 70px;
         text-align: right;

         @media only screen and (max-width: 400px) {
            display: none;
         }
      }

      &>td:nth-child(4),
      &>th:nth-child(4) {
         width: 90px;
         text-align: right;
      }

      &>td:nth-child(5) {
         width: 30px;
      }

      &>td.admin {
         width: 250px;

         @include respond(tablet) {
            width: 225px;
         }

         @include respond(mobile) {
            width: 30px;
         }
      }

      &>td:nth-child(6),
      &>th:nth-child(6) {
         width: 30px;
         text-align: center;

         @media only screen and (max-width: 375px) {
            display: none;
         }

         & .svg-inline--fa {
            cursor: pointer;
         }
      }
   }

   &__comment {
      font-size: 0.9rem;
      background-color: rgb(255, 245, 224);

      & .svg-inline--fa {
         transform: rotate(90deg);
      }

      &>div {
         display: flex;
         gap: 10px;
         align-items: center;

         &> :nth-child(2) {
            margin-right: auto;
         }
      }
   }

   &__actions {
      display: flex;
      gap: 15px;
   }

   &__total-price {
      font-size: 1.4rem;
      font-weight: 600;
      text-align: right;
   }

   &__quickies {
      display: flex;
      gap: 10px;
      padding-top: 15px;
      align-items: center;

      @include respond(tabletAndMobile) {
         align-self: stretch;
         justify-content: flex-end;
         margin-bottom: 10px;
      }
   }

   &__cancel {
      margin-top: 20px;
      text-align: right;

      @include respond(tabletAndMobile) {
         text-align: center;
      }
   }

   &__delivery-date-edit,
   &__meat-delivery-date-edit {
      display: flex;
      flex-direction: column;
      gap: 10px;
   }
}