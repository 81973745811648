.error-message {
   text-align: center;
   color: $redColor;
   margin: 5px 0;
   position: relative;

   &--bordered {
      border: 1px solid $redColor;
      border-radius: 5px;
      background-color: $redBackgroundColor;
      padding: 8px 14px;
   }

   &--left {
      text-align: left;
   }

   &--success {
      border-color: $accentColor;
      background-color: rgba($color: $accentColor, $alpha: 0.1);
      color: $accentColor;
   }

   &--bigger {
      font-weight: 600;
      font-size: 18px;
   }

   &__close {
      position: absolute;
      right: 10px;
      top: calc(50% - 8px);

      & .svg-inline--fa {
         font-size: 0.95rem;
         cursor: pointer;
      }
   }
}