.edit-product-modal .edit-modal {
   &__grid {
      grid-template-columns: 1fr 1fr;
      @include respond(tabletAndMobile) {
         grid-template-columns: 1fr;
      }
   }
   &__colspan {
      grid-column: 1 / 3;
      @include respond(tabletAndMobile) {
         grid-column: 1 / 2;
      }
   }
}

.edit-product-modal {
   &__two-columns {
      display: flex;
      gap: 8px;
      & > div:first-child {
         flex-grow: 1;
      }
      & > div:last-child {
         width: 130px;
         flex-shrink: 0;
      }
      & > button {
         flex-shrink: 0;
      }
   }
   &__three-columns {
      display: grid;
      gap: 8px;
      grid-template-columns: 1fr 1fr 1fr;
      @include respond(tabletAndMobile) {
         grid-template-columns: 1fr;
      }
   }
}
