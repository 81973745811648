.news-page {
   text-align: center;
   &__filter {
      @include respond(tabletAndMobile) {
         & .filter {
            flex-direction: column;
            padding: 20px $mobilePaddingX;
            align-items: stretch;
            & :first-child {
               margin-bottom: 30px;
               padding: 7px 35px;
               border-top: 1px solid $separatorColor;
               border-bottom: 1px solid $separatorColor;
               font-size: 1.45rem;
               align-self: center;
            }
         }
         &--hidden {
            display: none;
         }
         &--visible {
            display: unset;
            position: fixed;
            inset: 0;
            background-color: white;
            z-index: 3000;
         }
      }
   }
   &__filters-toggle {
      display: none;
      @include respond(tabletAndMobile) {
         display: unset;
      }
   }
   &__pagination {
      display: flex;
      justify-content: center;
   }
   &__list {
      display: flex;
      flex-direction: column;
      gap: 50px;
      margin: 50px 0;
      @include respond(tabletAndMobile) {
         gap: 30px;
         margin: 20px 0;
      }
   }
}
