.card {
   display: flex;
   flex-direction: column;

   &__title,
   &__text,
   &__date {
      text-align: center;
   }

   &__image {
      object-fit: cover;
      cursor: pointer;
   }

   &__container {
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      align-items: center;
      flex-grow: 1;
   }

   &__title a {
      text-decoration: none;
      color: $primaryTextColor;
   }

   &__bottom {
      margin-top: auto;
      display: flex;
      align-items: flex-end;
      gap: 20px;
   }

   &__date {
      color: $secondaryTextColor;
      font-size: 0.95rem;
   }

   &__text {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
   }

   &__tags {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      & .tag {
         font-size: 0.95rem;
      }
   }
}

.card--top {
   & .card__image {
      height: 250px;
      width: 100%;

      @include respond(mobile) {
         height: 180px;
      }
   }

   & .card__text {
      -webkit-line-clamp: 3;
   }

   & .card__bottom {
      flex-direction: column;
      align-items: center;
   }
}

.card--right {
   flex-direction: row-reverse;
}

.card--left {
   flex-direction: row;
}

.card--left,
.card--right {
   height: 400px;

   @include respond(tabletAndMobile) {
      height: unset;
      flex-direction: column;
   }

   & .card__container {
      padding: 40px;

      @include respond(tabletAndMobile) {
         padding: 20px;
      }
   }

   & .card__image {
      width: 35vw;
      height: 100%;

      @include respond(tabletAndMobile) {
         height: 180px;
         width: 100%;
      }
   }

   & h3 {
      font-size: 1.45rem;
   }

   & .card__text {
      align-self: stretch;
      padding-top: 18px;
      border-top: 1px solid $separatorColor;
      -webkit-line-clamp: 6;
   }

   & .card__bottom {
      align-self: stretch;
      justify-content: space-between;
      flex-direction: row;

      @include respond(tabletAndMobile) {
         flex-direction: column;
         align-items: center;
      }
   }
}