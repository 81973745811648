.user-orders {
   &__table {
      display: table;
      width: 100%;
      border-spacing: 0 10px;
   }
   &__header {
      display: table-row;

      & > div {
         display: table-cell;
         vertical-align: middle;
         white-space: pre;
         font-weight: 600;
         padding: 10px 6px;
         background-color: $primaryBackgroundColor;
         border-top: 1px solid #dee2e6;
         border-bottom: 1px solid #dee2e6;
      }
      & > div:first-child {
         padding-left: 1rem;
         border-radius: 5px 0 0 5px;
         border-left: 1px solid #dee2e6;
      }
      & > div:last-child {
         padding-right: 1rem;
         border-radius: 0 5px 5px 0;
         border-right: 1px solid #dee2e6;
      }
   }

   &__no-order {
      margin: 40px 0;
      text-align: center;
   }
}
